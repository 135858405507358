import React from 'react';
import { string } from 'prop-types';
import loadable from '@loadable/component';

import {
  LOYALTY_SHARE_ROUTE, LOYALTY_ROUTE, LOYALTY_STAMP_ROUTE, LOYALTY_REDEEM_ROUTE,
} from 'constants/navigation';

import {
  SHARE, RETRY, LOYALTY_REDEEM, LOYALTY_STAMP,
} from 'constants/font-awesome';

import useStyles from '../../styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/three'));
const Page = loadable(() => import('components/page'));
const LazyUpdateLoyaltyStamp = loadable(() => import('views/loyalty-card/loyalty/update/update-stamp'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const Typography = loadable(() => import('@material-ui/core/Typography'));

const SuspendedStampLoyaltyPage = ({ passId = '' }) => {
  const styles = useStyles();

  return (
      <Page
        title="Stamp Loyalty Card"
        description="Add loyalty stamps to Loyalty card.">
        <LazyBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
          Stamp Loyalty Card
        </Typography>
        <div className={styles.content}>
          <LazyUpdateLoyaltyStamp passId={passId} />
        </div>
        <LazyOverrideFooter
          navItems={[
            { route: `${LOYALTY_ROUTE}?passId=${passId}`, icon: RETRY, message: 'Search Again' },
            { route: `${LOYALTY_STAMP_ROUTE}?passId=${passId}`, icon: LOYALTY_STAMP, message: 'Stamp' },
            { route: `${LOYALTY_REDEEM_ROUTE}?passId=${passId}`, icon: LOYALTY_REDEEM, message: 'Redeem' },
            {
              route: `${LOYALTY_SHARE_ROUTE}?passId=${passId}`,
              icon: SHARE,
              message: 'Share',
            },
          ]}
          currentItem="Stamp"
          />
      </Page>
  );
};

SuspendedStampLoyaltyPage.propTypes = { passId: string };

export default SuspendedStampLoyaltyPage;
